'use strict';

import shopifyBuy from 'shopify-buy';

import * as Utils from './utils.js'
import * as VueCart from './vue-cart.js'
import { readCookie, setCookie, cookieHasExpired, createNewCookie, removeCookie, hudsonCookieName, addCookieData } from './cookies.js'

/* Note ShopifyBuy.buildClient() rather than Client.buildClient when using UMD version of shopify buy sdk */



// Initializing the Shopify client
const client = shopifyBuy.buildClient({
  domain: 'hudson-records-ltd.myshopify.com',
  storefrontAccessToken: '3dda66064b628b576766559ba593a392'
});

const $product = document.querySelectorAll('.shopify-product');

let checkout,
    cartItems = [],
    cartQuantity = 0,
    previousFocusItem;

// ===== INITIALISE ===== //

export const shopify = () => {
    /* ==============================================================================================
    Loop through .shopify-product elements, get the product id stored in data-product-props attr and convert to base64.
    Fetch the product from Shopify API and store the product and variant as variables.
    Loop through variants and build html inside shopify-product element
    /  ============================================================================================== */
    document.querySelectorAll('.shopify-product').forEach($productEl => {

        // get product props from product element and set as an array
        const productProps = getPropsFromAttr($productEl);

        // fetch the product from Shopify API and add variants to the dom
        fetchShopifyProduct_promise( Utils.base64(productProps.productId,'Product') ).then((product) => {

            // create a data object
            let data = [];

            // add the shopify product data as 'product'
            data['product'] = product;
            data['productProps'] = productProps;

            if ( $productEl.classList.contains('options') ) {
                renderVariants( $productEl, $productEl.querySelector('.variants'), data, 'options' ); // 3rd param 'type' defines whether to render variants or variant options ('variants'|'options')
            } else {
                renderVariants( $productEl, $productEl.querySelector('.variants'), data, 'variants' ); // 3rd param 'type' defines whether to render variants or variant options ('variants'|'options')
            }

            addButtonEventListeners($productEl);
        }).catch(error => { console.error('Fetch error: ' + error.message); });

    });
    updateCartFromRemoteCheckout();

    // reevaluate cart lineItem heights on window resize
    window.addEventListener('resize', () => {
        setLineItemHeights();
    });
} // const shopify



const preloadImages = (variant) => {
    // preload shopify images so they're cached before adding to cart ui
    let image = new Image();
    image.src = variant.image.src;
}
export const getPrice = (price) => {
    // Shopify now return GraphModel2 where price needs to be destructured
    const { amount } = price
    let discountAmount = document.body.getAttribute('data-sda');

    let discountPrice;

    if ( discountAmount !== undefined && discountAmount !== null ) {
        discountPrice = (parseFloat(amount) * ((100 - parseFloat(discountAmount)) / 100 )).toFixed(2);
    } else {
        discountPrice = amount
    }
    return discountPrice
}
const buildVariantInnerHtml = (data, variant) => {
    return '<div class="flex">\
                <div class="block self-center leading-none uppercase font-hudson-bold tracking-wide text-xs sm:text-sm md:text-xs lg:text-sm">'+variant.title+'</div>\
            </div>\
            <div class="flex-1 flex">\
                <div class="price px-3 text-right w-full leading-none uppercase font-hudson-bold tracking-wide text-xs sm:text-sm md:text-xs lg:text-sm text-brand-gray-upper block self-center">£'+getPrice(variant.price)+'</div>\
            <div class="text-right leading-none uppercase font-hudson-bold tracking-wide align-baseline flex">\
                <a class="button add-to-basket js-toggle-popup align-middle self-center inline-block text-white bg-brand-red hover:bg-gray-900 plus-icon-after icon-after icon-light" data-popup="cart" data-variant-id="'+variant.id+'" data-release-date="'+data.productProps.releaseDate+'">\
                    <span class="hidden xs:inline md:hidden lg:inline">Add to basket</span>\
                    <span class="inline xs:hidden md:inline lg:hidden">Buy</span>\
                </a>\
            </div>'
}
const buildOptionsHtml = (option) => {
    return '<option>'+option.value+'</option>';
}
const renderVariants = ($productEl, $el, data, type) => {
    // remove loading icon
    $el.querySelector('.loading-icon').classList.remove('active');

    if ( type == 'variants' ) {
        data.product.variants.forEach((variant, i) => {
            // create and populate
            let $variant = document.createElement('div');
            $variant.classList = `variant text-black border-gray-200 ml-5 mr-3 py-3 flex sm:flex-no-wrap ${i > 0 ? 'border-t' : ''}`
            $variant.innerHTML = buildVariantInnerHtml(data,variant);

            $el.appendChild($variant);
            preloadImages(variant);
        })
    }

    if ( type == 'options' ) {
        // build select element for each option
        let $options = document.createElement('div');
        $options.classList = 'options-element flex flex-wrap flex-1 py-3 pb-0 border-t border-gray-200 px-5';
        $el.appendChild($options);

        $options = $productEl.querySelector('.options-element');

        data.product.options.forEach(option => {
            // add options outer and empty select element
            let optionsHtml = '<label class="block text-brand-gray text-xs font-hudson-bold uppercase">'+option.name+'</label><select class="text-left p-1 mr-2 mb-4 text-xs border-gray-200 border-2 rounded-md bg-white" data-name="'+option.name+'"></select>';
            Utils.append($options, 'div', 'option-'+option.name, optionsHtml);

            // append each option to select element
            option.values.forEach(value => Utils.append($el.querySelector('.option-'+option.name+' select'),'option','',value.value));
        });

        // create add to basket button
        let buttonHtml = '<a class="button add-to-basket js-toggle-popup align-middle self-center inline-block text-white bg-brand-red hover:bg-gray-900 plus-icon-after icon-after icon-light" data-popup="cart" data-variant-id="" data-release-date="'+data.productProps.releaseDate+'">'+
            '<span class="hidden xs:inline">Add to basket</span>'+
            '<span class="inline xs:hidden">Buy</span>'+
        '</a>';

        Utils.append($options,'div','button-wrap-element py-3 md:pt-0 md:pb-3 lg:py-3 text-right flex-1 flex justify-end md:justify-start lg:justify-end align-baseline',buttonHtml);

        // Add on change event listeners to select elements
        $el.querySelectorAll('select').forEach($select => {
            $select.addEventListener('change', () => {
                let variant = getVariantByOptions( data, getSelectValues($el) );
                setButtonFromSelectedOptions($el, variant);
                setProductImageByVariant($productEl, variant);
            });
        });

        // initially set the button's attr
        let variant = getVariantByOptions( data, getSelectValues($el) );
        setButtonFromSelectedOptions($el, variant);
        setProductImageByVariant($productEl, variant);

        // preload images
        data.product.variants.forEach(variant => preloadImages(variant));
    }
}
const setButtonFromSelectedOptions = ($el, selectedVariant) => {
    // get variant id and add it as an attribute to the button
    $el.querySelector('.button').setAttribute('data-variant-id', selectedVariant.id );
}
const setProductImageByVariant = ($productEl, variant) => {
    if ( $productEl.classList.contains('set-image-by-variant') ) {
        $productEl.querySelector('.product-image img').setAttribute('src', variant.image.src);
    }
}
const addButtonEventListeners = ($productEl) => {
    $productEl.querySelectorAll('.add-to-basket.button').forEach($button => {
        $button.addEventListener('click', () => {
            VueCart.vm.addCartItem($button.getAttribute('data-variant-id'),$button.getAttribute('data-release-date'));
            openCart();
        });
    });
}
const getSelectValues = ($el) => {
    // return the selected options as an array of values from the $el select
    let selectedOptions = [];
    $el.querySelectorAll('select').forEach($select => {
        let option = $select.value;
        selectedOptions.push(option);
    });
    return selectedOptions;
}
const getVariantByOptions = (data, options) => {
    // return the variant object that matches the array of options passed in

    // bit hacky but a short term solution to get variants with either 1 or two options working...
    // I should probably be using .map

    if ( options.length > 1 ) {
        return data.product.variants.filter(variant => {
            return variant.selectedOptions[0].value == options[0]
        }).filter(variant => {
            return variant.selectedOptions[1].value == options[1]
        })[0];
    } else {
        return data.product.variants.filter(variant => {
            return variant.selectedOptions[0].value == options[0]
        })[0];
    }
}

const updateCartFromRemoteCheckout = () => {
    // Load local cart then do stuff with the checkout object
    loadShopifyRemoteCheckout_promise().then((remote_checkout) => {
        checkout = remote_checkout;
        // send to Vue cart
        VueCart.vm.setCartFromCheckout(checkout);
    });
}
const getPropsFromAttr = ($el) => {
    return JSON.parse($el.getAttribute('data-props'));
}
export const openCart = () => {
    document.body.classList.add ('overflow-hidden');
    Utils.actionClass('#cart','add','active');
}
export const closeCart = () => {
    document.body.classList.remove('overflow-hidden');
    Utils.actionClass('#cart','remove','active');
}
export const updateCartButton = (number) => {
    document.querySelectorAll('.cart-button').forEach($cartButton => {
        $cartButton.querySelectorAll('span').forEach($el => $el.textContent = number);
    });
}
export const setLineItemHeights = () => {
    // wait for vue to build cart, then get the heights of elements. There must be a way to do this with vue (refs?)...
    setTimeout(() => {
        document.querySelectorAll('.line-item').forEach($el => {
            // reset height
            $el.style.height = 'auto';
            // set new height
            $el.style.height = $el.offsetHeight + 'px';
        });
    },100)
}
export const sumLineItemQuantities = (lineItems) => {
    let quantity = 0;
    lineItems.forEach((lineItem) => {
        quantity = quantity + lineItem.quantity
    });
    return quantity;
}
export const sumLineItemPrices = (lineItems) => {
    let total = 0;
    lineItems.forEach((lineItem) => {
        total = total + ( parseFloat(lineItem.price) * parseInt(lineItem.quantity) )
    });
    return '£'+total.toFixed(2);
}
// fetch shopify product and resolve product object
const fetchShopifyProduct_promise = (productId) => {
    return new Promise((resolve, reject) => {
        try {
            client.product.fetch(productId).then((product) => {
                resolve(product);
            });
        } catch(e) {
            console.error(e)
        }
    });
}
const getUrlParamLineItemsAsArray = (paramLineItems) => {
    console.log('Checkout ID found in URL Param: '+paramLineItems);
    let lineItems = [];

    paramLineItems.split('&&').forEach(unformattedLineItem => {
        let lineItemArray = unformattedLineItem.split('_');
        lineItems.push({
            productId:lineItemArray[0],
            variantTitle:lineItemArray[1].replaceAll('%20',' '),
            quantity:parseInt(lineItemArray[2])
        });
    })
    return lineItems
}
const getProductReleaseDateByProductId = (productId) => {
    return new Promise((resolve, reject) => {
        // query craft for the release date for this product
        const productByShopifyProductId = `
            query productByShopifyProductId( $shopifyProductId:[QueryArgument] ) {
                entries(section:"products", shopifyProductId:$shopifyProductId) {
                        title
                        id
                ... on products_hudsonRelease_Entry {
                      releaseDate
                    }
                ... on products_distributionProduct_Entry {
                      releaseDate
                    }
                }
            }
        `;

        Utils.gql(productByShopifyProductId, { "shopifyProductId":productId }).then(response => {
            const entries = response.data.entries;
            if ( entries.length ) {
                const entry = entries[0];
                if ( entry.releaseDate !== undefined ) {
                    resolve(entry.releaseDate)
                } else {
                    reject('No release date set for entry #'+entry.id)
                }
            } else {
                reject('Entry not found with productId: '+productId)
            }

        })
    })
}
export const loadShopifyRemoteCheckout_promise = () => {
    return new Promise((resolve, reject) => {
        /* ============================================================== **
        First check if there's a url param of 'lineitems' which could be passed in
        from an abandoned cart email notification, the content of which has been set in Shopify backend
        email notifications.

        Each abandoned line item, delimited by '&&' contains '_' delimited values for
        productId, variantTitle (actually lineItem title) and line item quantity.

        -Create a new checkout
        -Format the lineItems data from the url param string
        –Fetch the product from shopify api
        –by settings both strings to lowercase first, find the variant within that product that matches the line item variant title
        –find the corresponding product in craft and get it's release date
        -add the variant to the new checkout, and open the cart model
        ** ============================================================== */

        // check for checkout url param
        const paramLineItems = Utils.getUrlParam('lineitems');
        if ( paramLineItems !== undefined && paramLineItems.length ) {
            let checkout;
            // create a new checkout
            client.checkout.create().then((remote_checkout) => {
                // remove the old cookie if it exists
                if ( cookie ) {
                    removeCookie(hudsonCookieName)
                }

                // create a new cookie with the remote_checkout.id
                createNewCookie(hudsonCookieName,remote_checkout.id)

                getUrlParamLineItemsAsArray(paramLineItems).forEach(lineItem => {
                    fetchShopifyProduct_promise(Utils.base64(lineItem.productId,'Product')).then((product) => {
                        if ( product ) {
                            // (set strings for matching to lowercase)
                            const lineItemVariantTitle = lineItem.variantTitle.toLowerCase();
                            // get the variant from the fetched product by title
                            const variant = product.variants.filter(variant => lineItemVariantTitle.includes(variant.title.toLowerCase()))[0];
                            if ( variant !== undefined ) {
                                let productId = lineItem.productId;
                                getProductReleaseDateByProductId(productId).then(releaseDate => {
                                    // add line items to checkout
                                    addLineItems_promise(variant.id,lineItem.quantity,releaseDate,remote_checkout.id).then((checkout) => {
                                        // resolve the parent promise (loadShopifyRemoteCheckout_promise)
                                        resolve(checkout);
                                        // open the cart model
                                        openCart();
                                    })
                                });
                            } else {
                                console.log('No matching variant found in Shopify for '+lineItem.variantTitle);
                            }
                        }
                    })
                });
            })

        } else {
            let cookie = readCookie(hudsonCookieName)

            if ( cookie && !cookieHasExpired(cookie) ) {
                // cookie exists and has not expired
                console.log('Previous valid cookie found.')
                // fetch shopify checkout using checkout id in cookie.checkout
                //console.log(cookie)
                client.checkout.fetch(cookie.checkout).then((remote_checkout) => {
                    // resolve remote checkout object
                    resolve(remote_checkout);
                }).catch(e => { console.error('Error fetching checkout: ' + e.message); });

            } else {
                // cookie does not exist, or has exired

                // create a new shopify checkout
                client.checkout.create().then((remote_checkout) => {
                    // remove the old cookie if it exists
                    if ( cookie ) {
                        removeCookie(hudsonCookieName)
                    }

                    // create a new cookie with the remote_checkout.id
                    createNewCookie(hudsonCookieName,remote_checkout.id)
                    cookie = readCookie(hudsonCookieName)
                    // resolve remote checkout object
                    resolve(remote_checkout)
                    //console.log(cookie)
                }).catch(e => { console.error('Error creating checkout: ' + e.message); });
            }
        }
    })
}

const getVariantFromProductById_promise = (product, variantId) => {
    // param is base64 encoded productId
    return new Promise((resolve, reject) => {
        product.variants.forEach((variant) => {
            if ( variant.variantId == variantId ) {
                resolve(variant);
            }
        });
    });
}

/* ================================================ **

Shopify Api will let us either completely remove a line item with client.checkout.removeLineItems passing in lineItem.id,
or update quantity of line item with client.checkout.updateLineItems passing in lineItem.id and new quantity.

Therefore if there's a quantity of more than 1 in the cart, I must use updateLineItems and pass in new quantity, or
if there's only a quantity of 1 in the cart I must use removeLineItems to remove it comepletely.

** ================================================ */
export const updateLineItems_promise = (lineItemId,quantity,checkoutId) => {
    return new Promise((resolve,reject) => {
        client.checkout.updateLineItems(checkoutId, [{ id:lineItemId, quantity:quantity }]).then(remote_checkout => {
            checkout = remote_checkout;
            resolve(checkout);
        }).catch(e => console.error('Error updating line items: '+e));
    });
}
export const addLineItems_promise = (variantId,quantity,releaseDate,checkoutId) => {
    let customAttrs = [{key:'Released',value:Utils.getNiceDateFromAtom(releaseDate)}];
    return new Promise((resolve,reject) => {
        client.checkout.addLineItems(checkoutId, [{ variantId:variantId, quantity:quantity, customAttributes:customAttrs }]).then(remote_checkout => {
            checkout = remote_checkout;
            resolve(checkout);
        }).catch(e => console.error('Error adding line items: '+e));
    });
}
export const addDiscount = (discountCode, checkoutId) => {
    return new Promise((resolve) => {
        client.checkout.addDiscount(checkoutId,discountCode).then(checkout => {
            resolve(checkout)
        })
    })
}
