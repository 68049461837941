import twitterFetcher from 'twitter-fetcher';

export const twitter = ($el:HTMLElement) => {

  const maxTweets:string|null = $el.getAttribute('data-max-tweets') 
  const showImages:string|null = $el.getAttribute('data-show-images')


    const config = {
      "profile": {"screenName": 'hudson_records'},
      "domId": $el.getAttribute('id'),
      "maxTweets": maxTweets ? parseInt(maxTweets) : 1,
      "enableLinks": true,
      "showUser": true,
      "showTime": true,
      "showImages":showImages ? parseInt(showImages) : 0,
      "lang": 'en',
      "customCallback":handleTweets()
    };
    twitterFetcher.fetch(config);
}
const handleTweets = () => {
    document.querySelectorAll('.show-on-twitter-fetched').forEach($el => $el.classList.remove('hidden') );
}

