'use strict';

import * as Utils from './utils.js';

interface SongkickArtist {
    "artistName": string,
    "artistUrl": string,
    "songkickId": string
}
interface ResultsEvent {
    "id": number,
    "displayName": string,
    "type": string,
    "uri": string,
    "status": string,
    "popularity": number,
    "start": {
        "date": string,
        "datetime": string|null,
        "time": string|null
    },
    "performance": {
            "id": number,
            "displayName": string,
            "billing": "headline"|"support",
            "billingIndex": number,
            "artist": {
                "id": number,
                "displayName": string,
                "uri": string,
                "identifier": 
                    {
                        "mbid": string,
                        "href": string
                    }[]
            }
        }[],
    "ageRestriction": string|null,
    "flaggedAsEnded": boolean,
    "venue": {
        "id": number,
        "displayName": string,
        "uri": string|null,
        "metroArea": {
            "displayName": string|null,
            "country": {
                "displayName": string|null
            },
            "id": number,
            "uri": string
        },
        "lat": number,
        "lng": number
    },
    "location": {
        "city": string,
        "lat": number,
        "lng": number
    }
}
type ArtistEvent = SongkickArtist & ResultsEvent
type DateTimeTypes = "datetime" | "date"
interface SongkickEventFlat extends ArtistEvent {
    dateTimeType: DateTimeTypes,
    startDateObject: Date
}
const getFormattedDate = (dateObject, dateTimeType) => {
    // split date into an array: keys 0 = year, 1 = month, 2 = day
    let dateHtml = '<span class="whitespace-nowrap">\
                <span class="day">'+dateObject.getDate()+'</span>\
                <span class="month">'+Utils.getMonthFromNumber(dateObject.getMonth())+'</span>\
            </span>\
            <span class="year mr-1">'+dateObject.getFullYear()+'</span>';

    let timeHtml = '<br class="xl:hidden"/>\
            <span class="startTime text-2xs mt-1 xl:mt-0">'+dateObject.getHours()+':'+dateObject.getMinutes().toFixed(1).replace('.','').slice(0,2)+'</span>';

    if ( dateTimeType == 'datetime' ) {
        return dateHtml + timeHtml;
    } else {
        return dateHtml
    }
}
const getPerformance = (eventItem:SongkickEventFlat) => {
    if ( eventItem.performance.length > 1 ) {
        // there's more than one performance
        // console.log(event.performance);
        const performances = eventItem.performance

        // sort by billing index
        performances.sort((a,b) => b.billingIndex - a.billingIndex)

        return performances.map((performance,i) => {
            if ( i === 0 ) {
                // headline artist
                return `<a class="hover:text-brand-blue" href="${eventItem.artistUrl}">${performance.artist.displayName}</a>`
            } else {
                // support artist
                return `<span class="text-xs">${performance.artist.displayName}</span>`
            }
        }).join(`<br /> + `)
    } else {
        // there's not more than one performance
        return `<a class="hover:text-brand-blue" href="${eventItem.artistUrl}">${eventItem.artistName}</a>`
    }
}
const buildEventHtml = (eventItem:SongkickEventFlat) => {
    let $eventLi = document.createElement('li');
    const classList = ['flex','items-center','bg-white','border-t','border-gray-200','leading-none','text-sm','xl:text-base']
    classList.forEach(className => {
        $eventLi.classList.add(className)
    })
    $eventLi.innerHTML = `
        <div class="flex flex-1 items-center">
            <div class="date w-1/4 xs:w-1/3 px-4 md:px-6 md:pl-10 py-2 md:py-4">${getFormattedDate(eventItem.startDateObject, eventItem.dateTimeType)}</div>
            <div class="md:flex w-full items-center py-3 md:py-0">
                <div class="artist uppercase leading-tight flex-1 px-4 md:px-6 pb-1 md:py-2 md:py-4">
                    ${getPerformance(eventItem)}
                </div>
                <div class="venue flex-1 px-4 md:inline md:block md:px-6 pb-1 md:py-2 md:py-4 text-xs leading-tight xs:text-sm">
                    <a class="hover:text-brand-blue" href="${eventItem.venue.uri}" target="_blank">${eventItem.venue.displayName.replace('@','<br>@ ')}</a>
                </div>
                <div class="town flex-1 px-4 md:inline md:block md:px-6 md:py-2 md:py-4">${eventItem.location.city}</div>
            </div>
        </div>
        <div class="tickets w-auto pr-4 pl-2 xs:px-4 md:px-6 py-2 md:py-4 md:mr-2 md:text-right">
            <a class="button text-center text-white bg-brand-blue hover:bg-black self-center align-middle" href="${eventItem.uri}" target="_blank">
                Tickets
            </a>
        </div>
    `
    document.querySelector('#events ul')?.appendChild($eventLi);
}
const hideLoadingIcon = ($parent) => {
    $parent.querySelector('.loading-icon').classList.remove('active');
}

export const songkick = async (songkickArtists:SongkickArtist[]) => {

    const buildSongkickEndpoint = (songkickId:string) => {
        return 'https://api.songkick.com/api/3.0/artists/'+songkickId+'/calendar.json?apikey=P9O3MNaKCG7VEtSF';
    }
    
    const artistEvents:SongkickEventFlat[] = await Promise.all(songkickArtists.map(async (artist:SongkickArtist) => {
        const endpoint = buildSongkickEndpoint(artist.songkickId)
        const response = await fetch(endpoint)
        if ( response.status !== 200 ) {
            console.error('Looks like there was a problem. Status Code: ' + response.status)
        }
        const { resultsPage } = await response.json()

        if ( 'event' in resultsPage.results ) {
            return resultsPage.results.event.map((event:ResultsEvent) => {
                return {
                    ...event,
                    ...artist
                }
            })
        }
    })).then(events => {
        const flatEvents:SongkickEventFlat[] = [] 
        events.filter(event => Boolean(event)).forEach((arr:ArtistEvent[]) => {
            arr.forEach((event:ArtistEvent) => {

                let key: DateTimeTypes = "date"

                if ( event.start && event.start.datetime ) {
                    key = 'datetime'
                }
                const getStartDateObject = () => {
                    if ( key === 'datetime' && event.start.datetime ) {
                        return new Date(event.start.datetime)
                    } else {
                        return new Date(event.start.date)
                    }
                }
                const flatEvent:SongkickEventFlat = {
                    ...event,
                    dateTimeType: key,
                    startDateObject: getStartDateObject()
                }

                flatEvents.push(flatEvent)
            })
        })
        return flatEvents.sort((a, b) => a.startDateObject.getTime() - b.startDateObject.getTime());
    })

    // output each
    artistEvents.forEach((flatEvent:SongkickEventFlat) => buildEventHtml(flatEvent));


    hideLoadingIcon(document.querySelector('#events'));
    // show the events container
    // ( events parent element should have class of events-container and hidden )

    if ( artistEvents.length > 0 ) {
        document.querySelector('.events-container')?.classList.remove('hidden');
    }
}
