import { setAttributesToNode } from './utils.js'
import { readCookie, hudsonCookieName } from './cookies.js'
import { getPasscodeEntryById } from './graphql'

export const forms = () => {

    // problems with recaptcha... setting human to true for now
    let human = true;

    const emailRegEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

    // test to run recaptcha test in production only.
    if ( document.body.getAttribute('data-env') == 'dev' ) {
        human = true;
    }

    //window.recaptcha_success = function() { human = true; console.log('Recaptcha Success') }
    //window.recaptcha_failure = function() { human = false; console.log('Recaptcha Fail') }

    const validateEmail = (email:string) => {
        return ( email.length > 4 && emailRegEx.test(email) );
    }
    const validateText = (val:string, minLength:number) => {
        return val.length > minLength;
    }
    const updateValidFlag = ($input:HTMLElement, action:"add"|"remove") => {
        // requires input to have next sibling element as '<div class="valid-flag hidden"></div>'
        const $validFlag = $input.parentElement?.querySelector('.valid-flag')
        if ( $validFlag ) {
            if ( action == 'add' ) {
                $validFlag.classList.remove('hidden');
            }
            if ( action == 'remove' ) {
                $validFlag.classList.add('hidden');
            }
        }
    }
    const setInputAsValid = (boolean:boolean, $input:HTMLInputElement) => {
        if ( boolean ) {
            updateValidFlag($input,'add');
        } else {
            updateValidFlag($input,'remove');
        }
    }
    const validateAll = ($form:HTMLFormElement) => {
        let totalValid = 0;

        let $emailInputs = $form.querySelectorAll('.js-validate-email-input');
        let $textInputs = $form.querySelectorAll('.js-validate-text-input');
        let $passwordInputs = $form.querySelectorAll('.js-validate-password-input');

        let totalInputs = $emailInputs.length + $textInputs.length + $passwordInputs.length;

        $emailInputs.forEach(($input) => {
            const el = $input as HTMLInputElement
            if ( validateEmail(el.value) ) {
                totalValid = totalValid + 1;
                updateValidFlag(el, 'add')
            } else {
                updateValidFlag(el, 'remove')
            }
        });
        $textInputs.forEach($input => {
            const el = $input as HTMLInputElement
            if ( validateText(el.value, 4) ) {
                totalValid = totalValid + 1;
                updateValidFlag(el, 'add')
            } else {
                updateValidFlag(el, 'remove')
            }
        });
        let password1:string = ''

        $passwordInputs.forEach(($input, i) => {
            
            const el = $input as HTMLInputElement
            if ( validateText(el.value, 4) ) {
                
                // make sure passwords match if there are two password inputs
                if ( i === 0 ) {
                    password1 = el.value
                    updateValidFlag(el, 'add')
                    totalValid++
                }
                if ( i === 1 ) {
                    if ( el.value === password1 ) {
                        updateValidFlag(el, 'add')
                        totalValid++
                    } else {
                        updateValidFlag(el, 'remove')
                    }
                }
            } else {
                updateValidFlag(el, 'remove')
            }
        })
        console.log({ totalInputs, totalValid })
        return totalInputs === totalValid;
    }

    const addHiddenInput = ($form:HTMLFormElement, name:string, value:string) => {
        let input = document.createElement('input');

        let attrs = [
            { attr:"class", value:"dynamic" },
            { attr:"type", value:"hidden" },
            { attr:"name", value:name },
            { attr:"value", value:value }
        ];

        $form.appendChild(setAttributesToNode(input,attrs));
    }
    const removeDynamicInput = ($form:HTMLFormElement) => {
        let $input = $form.querySelector('input.dynamic')

        if ($input !== null) {
            $input.remove();
        }
    }
    const addPagesVisitedInput = ($form:HTMLFormElement) => {
        const cookie = readCookie(hudsonCookieName)
        if (cookie) {
            const pagesVisited = cookie['pagesVisited'];
            if ( pagesVisited ) {
                pagesVisited.forEach((id:string) => {
                    addHiddenInput($form, "fields[pagesVisited][]", id);
                });
            }
        }
    }
    const addCSRF = (form:HTMLFormElement) => {
        const csrfToken = document.body.getAttribute('data-csrf')
        const csrfTokenName = document.body.getAttribute('data-csrf-name')

        if ( csrfToken && csrfTokenName ) {
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = csrfTokenName
            input.value = csrfToken
            form.appendChild(input)
        }
    }


    document.querySelectorAll('form.js-validate-form').forEach($form => {
        const form = $form as HTMLFormElement
        // Live input validation. Note because I can't detect browser autofill/autocomplete
        // revalidate these fields before submit
        form.querySelectorAll('.js-validate-email-input').forEach($input => {
            const input = $input as HTMLInputElement
            ['change','input'].forEach(evt => {
                input.addEventListener(evt, () => {
                    setInputAsValid(validateEmail(input.value), input)
                });
            });
        });
        form.querySelectorAll('.js-validate-text-input').forEach($input => {
            const input = $input as HTMLInputElement
            ['change','input'].forEach(evt => {
                input.addEventListener(evt, () => {
                    setInputAsValid(validateText(input.value,4), input)
                });
            });
        });
        // remove action hidden input on popup close
        // form parent element must be an ancestor of submit and popup buttons!
        form.parentElement?.querySelectorAll('.button.js-toggle-popup').forEach($button => {
            $button.addEventListener('click', () => {
                removeDynamicInput(form);
                // remove loading icons
                form.querySelectorAll('.button.loading').forEach($button => {
                    $button.classList.remove('loading');
                });
            });
        });
    });

    // Submit button events
    document.querySelectorAll('.js-form-submit').forEach($button => {
        $button.addEventListener('click', () => {
            
            let form = document.querySelector('#'+$button.getAttribute('data-form')) as HTMLFormElement | null

            if ( human && form ) {
                if ( validateAll(form) ) {
                    try {
                        $button.classList.add('loading');
                        form.submit();
                    }
                    catch {
                        console.log('Error submitting form');
                    }
                } else {
                    console.log('Form validation errors')
                }
            } else {
                console.log('Not human!');
            }

        });
    });
    document.querySelectorAll('.clear-url-params').forEach($button => {
        $button.addEventListener('click', () => {
            window.location.href = window.location.href.split('?')[0]
        });
    });
    document.querySelectorAll('.js-toggle-inline-field').forEach($button => {
        $button.addEventListener('click', () => {
            $button.classList.add('hidden');
            document.querySelector('#'+$button.getAttribute('data-field-id'))?.classList.add('active');
        });
    });
    document.querySelectorAll('form#register-user').forEach($form => {
        $form.querySelectorAll('.js-registration-form-submit').forEach($button => {
            $button.addEventListener('click', () => {
                $button.classList.add('loading')
            })
        })
    })
    document.querySelectorAll('#enter-password-button').forEach(button => {

        const formId:string|null = button.getAttribute('data-form')
        const buttonTitle:HTMLSpanElement|null = button.querySelector('span')
        const input:HTMLInputElement|null = button.querySelector('input')
        const submit:HTMLButtonElement|null = button.querySelector('#submit-button')

        const addClass = (el:HTMLElement, classNames:string[]) => {
            classNames.forEach(className => {
                el.classList.add(className)
            })
        }
        const removeClass = (el:HTMLElement, classNames:string[]) => {
            classNames.forEach(className => {
                el.classList.remove(className)
            })
        }

        if ( !formId || !buttonTitle || !input || !submit ) {
            console.error('missing html')
            return 
        }
        const form:HTMLElement|null = document.getElementById(formId)
        if ( !form ) {
            return
        }
        
        let state = 0

        const setState = (newState:0|1) => {
            state = newState

            if ( state === 1 ) {
                removeClass(button as HTMLElement, ['icon-after', 'arrow-icon-after', 'bg-gray-900', 'hover:bg-brand-red'])

                addClass(buttonTitle, ['hidden'])
                addClass(input, ['opacity-100', 'right-0'])
                addClass(submit, ['opacity-100', 'scale-100'])

                removeClass(input, ['opacity-0', 'pointer-events-none', 'right-full'])
                removeClass(submit, ['opacity-0', 'scale-50', 'pointer-events-none'])
            }
        }

        const _handleSubmit = async () => {
            submit.querySelector('.arrow-svg')?.classList.add('hidden')
            submit.querySelector('.loading-icon')?.classList.add('active')

            const passcode = input.value

            const valid = await getPasscodeEntryById(passcode)
            if ( valid.data?.entry?.id == passcode ) {
                const updateUserForm = form as HTMLFormElement
                updateUserForm.submit()
            } else {
                //window.location.href = `${window.location.href}?m=1&error=invalid`
            }
        }
        button.addEventListener('click', () => {
            if ( state !== 0 ) {
                return 
            }
            setState(1)
            input.focus()

            submit.addEventListener('click', () => {
                _handleSubmit()
            })
        })
    })

    // add csrf to all forms via js. CSRF token is rendered from server on <body> in data-attr, which is not cached.
    document.querySelectorAll('form').forEach((form:HTMLFormElement) => {
        console.log(`Adding CSRF to ${form.getAttribute('id')}`)
        addCSRF(form)
    })
}
