'use strict';

export const fader = ($el) => {
    let duration = 2,
        interval = 12;

    const $slides = $el.querySelectorAll('a.fi');
    const $captions = $el.querySelectorAll('.caption');

    const updateSlides = ($el, $slides, $captions, i) => {
        // reset class of old current
        $el.querySelectorAll('.current').forEach(item => item.classList.remove('current'));

        // add class to new current
        $slides[i].classList.add('current');
        $captions[i].classList.add('current');
    }
    if ( $slides.length > 1 ) {
        $el.classList.add('active');

        let i = 0;
        let z = 0;

        setInterval(() => {
            z++; // iterate z forever
            i < ($slides.length - 1) ? i++ : i = 0; // iterate i up to limit of number of slides, else start again

            updateSlides($el, $slides, $captions, i)

            $slides[i].setAttribute('style','z-index:'+z);
            $captions[i].setAttribute('style','z-index:'+(z+$slides.length));

        },(interval * 1000));
    }
}
