'use strict';

export const bindEvents = ($el) => {
    let t;
    let $submenu = document.querySelector('.'+$el.getAttribute('data-submenu'));

    const openSubmenu = ($submenu) => $submenu.classList.add('active');
    const closeSubmenu = ($submenu) => $submenu.classList.remove('active');

    const clearTimeoutClose = () => {
        clearTimeout(t);
        t = setTimeout(() => closeSubmenu($submenu),1500);
    }
    $el.addEventListener('click', () => {
        clearTimeout(t);
        $submenu.classList.toggle('active');
    });
    $el.addEventListener('mouseleave', () => clearTimeoutClose());
    $submenu.addEventListener('mouseover', () => clearTimeout(t));
    $submenu.addEventListener('mouseleave', () => clearTimeoutClose());
}
