'use strict';
import { createApp } from 'vue'
import * as Shopify from './shopify.js';
import * as Utils from './utils.js';
import { removeCookie, hudsonCookieName } from './cookies.js'

export const Cart = {
    delimiters: ['${', '}'],
    data() {
        return {
            cartItems: [],
            cartTotal:0,
            checkoutState:'confirmation',
            checkoutUrl:undefined,
            checkoutId:null,
            discountCode:document.body.getAttribute('data-sdc'),
            discountAmount:document.body.getAttribute('data-sda')
        }
    },
    methods: {
        getPrice(price) {
            return Shopify.getPrice(price)
        },
        setCartFromCheckout(checkout) {
            //console.log(checkout);
            this.checkoutState = 'working';
            this.cartItems = [];

            this.checkoutId = checkout.id;
            // add this checkout's checkout url
            this.checkoutUrl = checkout.webUrl;

            checkout.lineItems.forEach((lineItem) => {
                const title = lineItem.title.split('–');


                const releaseDateNice = lineItem.customAttributes[0].attrs.value.value;
                const variant = {
                    "artist":title[0],
                    "image":lineItem.variant.image.src,
                    "variantSelector":Utils.toKebab(title[0]+' '+title[1]+' '+lineItem.variant.title),
                    "productTitle":title[1],
                    "format":lineItem.variant.title,
                    "price":this.getPrice(lineItem.variant.price),
                    "quantity":lineItem.quantity,
                    "variantId":lineItem.variant.id,
                    "lineItemId":lineItem.id,
                    "releaseDate":releaseDateNice,
                    "isPreorder":Utils.isFuture(Utils.getAtomFromNice(releaseDateNice))
                };
                this.cartItems.push(variant);
            });
            this.updateCartTotals();

            // enable checkout button if there are lineItems in the checkout, else set it to 'empty')
            if ( this.cartItems.length ) {
                this.checkoutState = 'normal' // set back to 'normal'
            } else {
                this.checkoutState = 'empty'
            }
            Shopify.setLineItemHeights();
        },
        closeCart() {
            Shopify.closeCart();
            setTimeout(() => {
                this.checkoutState = 'normal';
            },1000);
        },
        addCartItem(variantId, releaseDate) {

            // set checkoutState
            this.checkoutState = 'working';

            // add the variant to the remote checkout
            Shopify.addLineItems_promise(variantId,1,releaseDate,this.checkoutId).then(checkout => {
                this.setCartFromCheckout(checkout);
                this.updateCartTotals();
            });
        },
        updateCartItem(cartItem, increment) {
            // set checkoutState
            this.checkoutState = 'working';

            let quantity = cartItem.quantity + increment;

            // update the cartItem quantity first
            this.updateCartItemQuantity(quantity,cartItem.variantId);
            this.updateCartTotals();

            // remove from lineItems
            Shopify.updateLineItems_promise(cartItem.lineItemId,quantity,this.checkoutId).then(checkout => {
                this.setCartFromCheckout(checkout);
                this.updateCartTotals();
            });
        },
        updateCartItemQuantity(quantity, variantId) {
            this.cartItems.forEach(cartItem => {
                if ( cartItem.variantId == variantId ) {
                    cartItem.quantity = quantity;
                }
            });
        },
        getCartItemQuantity(variantId) {
            this.cartItems.forEach(cartItem => {
                if ( cartItem.variantId == variantId ) {
                    return cartItem.quantity;
                }
            });
        },
        updateCartTotals() {
            // update the cart total
            this.cartTotal = Shopify.sumLineItemPrices(this.cartItems);

            // Update the cartButton by calculating sum of line item quantities
            Shopify.updateCartButton(Shopify.sumLineItemQuantities(this.cartItems));
        },
        hideLineItemWithZeroQuantity(cartItem) {
            if ( cartItem.quantity < 1 ) {
                return 'cart-hidden'
            }
        },
        resetCheckout() {
            // remove localStorage to force reset of checkout

            console.log('resetting checkout ')
            removeCookie(hudsonCookieName)

            // reload window to reset checkout and lineItem ids
            window.location = window.location.href;
        },
        checkout() {
            let checkoutWindow = window.open("", "_blank");
            // add discount to checkout if applicable
            if ( this.discountAmount !== undefined && this.discountAmount !== null ) {
                Shopify.addDiscount(this.discountCode, this.checkoutId).then(checkout => {
                    checkoutWindow.location = this.checkoutUrl;
                    checkoutWindow.focus()
                })
            } else {
                checkoutWindow.location = this.checkoutUrl;
                checkoutWindow.focus()
            }
            // add checkout confirmation window?
            // e.g. 'Did you complete your purchase? If not we can save your basket for later'
            // Buttons: 'Save for later', 'Yes, continue to website'
            setTimeout(() => {
                this.checkoutState = 'confirmation';
            },3000);
        }
    },
    mounted() { console.log('shopify cart mounted') }

};
export const cartApp = createApp(Cart);
export const vm = cartApp.mount('#cart-model');
