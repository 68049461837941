import * as Utils from './utils';
import * as Forms from './forms';
import * as Shopify from './shopify';
import * as Songkick from './songkick';
import * as Carousel from './carousel';
import * as Twitter from './twitter';
import * as Tags from './tags';
import * as Fader from './fader';
import * as Submenu from './submenu';
import * as ShopFilters from './shop-filters';
import DownloadsApp from './vue-downloads'

// Run
let club = document.body.getAttribute('data-section') == 'club' ? true : false

if ( !club ) {
    Shopify.shopify();
    Utils.navByPage( document.body.getAttribute('data-section'), document.body.getAttribute('data-entry-id') );
    Utils.bindPopupListeners();
    ShopFilters.shopFilters();
}



Utils.bindEscapeKeyListener();
Utils.buildRevealDelays();

Carousel.carousel();
Utils.checkTouch();


Forms.forms();



// run page
document.body.classList.remove('no-js');

if (navigator.userAgent.match(/firefox/i)) {
    document.body.classList.add('firefox');
}

document.querySelectorAll('.js-buy-action-title').forEach($el => {
    if ( Utils.isFuture($el.getAttribute('data-release-date')) ) {
        $el.innerHTML = 'Preorder Now'
    }
});
document.querySelectorAll('.fader').forEach($el => Fader.fader($el));

document.querySelectorAll('.status-tag').forEach($el => Tags.queryTag($el));

document.querySelectorAll('.preorder-tag').forEach($el => {
    const date:string|null = $el.getAttribute('data-date')

    const isPreorder = Utils.isFuture(Utils.getAtomFromNice(date))

    if ( isPreorder ) {
        $el.classList.remove('hidden');
    }
});

document.querySelectorAll('.link').forEach($el => {
    $el.addEventListener("mouseover",() => $el.classList.add('hovered'));
    $el.addEventListener("mouseleave",() => $el.classList.remove('hovered'));
});

document.querySelectorAll('.js-toggle-submenu').forEach($el => Submenu.bindEvents($el));

document.querySelectorAll('.js-play-video').forEach($el => {
    $el.addEventListener('click', () => Utils.buildIframeVideo($el));
});
document.querySelectorAll('#tweets').forEach($el => Twitter.twitter($el as HTMLElement));
document.querySelectorAll('#events').forEach($el => Songkick.songkick(songkickArtists));

document.querySelectorAll('.lightbox-img').forEach($el => {
    $el.addEventListener('click', () => Utils.buildLightbox($el));
});
document.querySelectorAll('.now-year').forEach($el => $el.innerHTML = Utils.todayDate.getFullYear().toString());

// show discount ribbon and discount notice (with class js-discount) if body attrs (conditional on server-side currentUser query)
let sda = document.body.getAttribute('data-sda')
if ( sda !== null && sda != 'undefined' && sda.length ) {
    document.querySelectorAll('.js-discount').forEach($el => $el.classList.remove('hidden'))
}

// rebuild all iframe srcs on window resize
window.addEventListener('resize', () => {
    Utils.rebuildAttribute('iframe.bandcamp', 'src');
})
// scroll to locally hashed hrefs

document.querySelectorAll('a[href*="#"]').forEach($el => {
    if ( $el.getAttribute('href') && $el.getAttribute('href')?.charAt(0) === '#') {
        const hash = $el.getAttribute('href')

        if ( hash ) {
            $el.addEventListener('click', (e) => {
                e.preventDefault()
                const anchor = document.querySelector(hash)
                if ( anchor ) {
                    anchor.scrollIntoView({ behavior:'smooth' })
                }
            })
        }
    }
})

// downloads vue app
const downloadsAppElement = document.getElementById('downloadsApp')
if ( downloadsAppElement ) {
    DownloadsApp.mount(downloadsAppElement)
}

const downloadSuccessButton = document.querySelector('.js-download-success-button')
if ( downloadSuccessButton ) {
    downloadSuccessButton.addEventListener('click', () => {
        const url =  downloadSuccessButton.getAttribute('data-url')
        if ( url ) {
            const a = document.createElement('a')
            a.href = url
            a.click()
        }
    })
}