'use strict';

import { gql } from './utils.js';

export const shopFilters = () => {
    if ( document.querySelector('.shop-filters') !== null ) {
        let $parentEl = document.querySelector('.shop-filters');
        // add product count to shop filters
        const $filterTarget = document.querySelector('.js-filter-target');
        const $filterItems = document.querySelectorAll('.js-filter-item');
        const $clearFiltersButton = document.querySelector('.js-clear-filters');

        // set the target height so it doesn't contract
        // $filterTarget.style.height = $filterTarget.clientHeight + 'px';


        const reset = () => {
            $filterItems.forEach($filterItem => $filterItem.classList.remove('text-brand-red'));
            ['bg-black','hover:bg-brand-gray'].forEach(className => {
                $clearFiltersButton.classList.remove(className);
            });
            ['bg-gray-200','pointer-events-none'].forEach(className => {
                $clearFiltersButton.classList.add(className);
            });
            $filterTarget.querySelectorAll('.card').forEach($card => {
                $card.classList.remove('hidden');
            });
        }

        const queryArtists = `
            query productsByArtistId($type: [String]) {
                entries(type: $type) {
                    ... on products_hudsonRelease_Entry {
                      id
                      title
                      primaryArtist {
                        id
                        title
                      }

                    }
                }
            }
            `;

        const getProductsByArtistId = (products, artistId) => {
            return products.filter(product => product.primaryArtist[0].id === artist.id )
        }
        if ( $clearFiltersButton ) {
            $clearFiltersButton.addEventListener('click',() => reset() );
        }

        gql( queryArtists, { "type":"hudsonRelease" } ).then(result => {
            let products = result.data.entries;

            // get array of artists, sort and remove duplicates
            let artists = products.map(entry => {
                return {
                    "artist":entry.primaryArtist[0].title,
                    "id":entry.primaryArtist[0].id
                }
            }).sort((a,b) => a.id - b.id);

            // remove duplicates
            artists = artists.filter(( artist, i ) => {
                if ( i > 1 ) { return artist.id !== artists[i-1].id }
            });
            //console.log(products);
            artists.forEach(artist => {
                // find products matching this artist Id
                // console.log(artist.id);
                let artistProducts = products.filter(product => product.primaryArtist[0].id == artist.id);
                artist['productEntries'] = artistProducts;
                //console.log('query artist.id: '+artist.id);
            });
            // console.log(artists);
                $filterItems.forEach($el => {
                    const artistId = $el.getAttribute('data-artist-id');
                    let $productCount = $el.querySelector('.product-count');
                    let artistEntry = artists.filter(item => item.id == artistId);
                    //console.log('data-artist-id: '+artistId);

                    if ( artistEntry.length ) {
                        // console.log(artistEntry)
                        $productCount.innerHTML = '('+artistEntry[0].productEntries.length+')';
                        $parentEl.classList.remove('hidden');
                        $el.classList.remove('hidden');

                        $el.addEventListener('click',() => {
                            reset();
                            // console.log($clearFiltersButton.classList);
                            ['bg-gray-200','pointer-events-none'].forEach(className => {
                                $clearFiltersButton.classList.remove(className);
                            });

                            ['bg-black','hover:bg-brand-gray'].forEach(className => {
                                $clearFiltersButton.classList.add(className);
                            });
                            $el.classList.add('text-brand-red');

                            // hide all
                            $filterTarget.querySelectorAll('.card').forEach($card => {
                                $card.classList.add('hidden');
                            });

                            artistEntry[0].productEntries.forEach(entry => {
                                $filterTarget.querySelectorAll('.entry-'+entry.id).forEach($el => $el.classList.remove('hidden'));
                            })

                        })
                    }
                });

                // re-evaluate on window resize event
                window.addEventListener('resize', () => {
                    reset();
                });


        });
    }
}
