type ErrorT = {
    message:string,
    extensions:{
        code:string,
        category:string
    }
}
export const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
}
export const gql = async ({ query, variables, authToken }: { query:string, variables?:Record<string,string>, authToken?:string }) => {
    const headers = getHeaders()
    if ( authToken ) {
        headers['Authorization'] = authToken
    }
    const getBody = () => {
        if (!variables) {
            return JSON.stringify({ query })
        } else {
            return JSON.stringify({ query, variables })
        }
    }
    const data = await fetch('/api', {
        method:'POST',
        headers,
        body:getBody()
    }).then(res => res.json())
    return data
}
export const registerUser = async (authToken:string, formData: {
    email:string,
    password:string,
    fullName:string
}) => {
    const query = `mutation Register {
        registerClubRegistered(
          email: "${formData.email}"
          password: "${formData.password}"
          fullName: "${formData.fullName}"
        ) {
          user {
            id
            fullName
          }
        }
      }
    `
    interface DataT {
        errors?:ErrorT[],
        data?:{
            registerClubRegistered: {
                user: {
                    id:string,
                    fullName:string
                }
            }
        }
    }
    const data:DataT = await gql({ query, authToken })
    return data
}
export const sendActivation = async (authToken:string, formData:{ 
    email:string 
}) => {
    const query = `mutation ResendActivation {
        resendActivation(email: "${formData.email}")
    }`
    interface DataT {
        errors?:ErrorT[],
        data?:{
            resendActivation: "You will receive an email if it matches an account in our system"
        }
    }
    const data:DataT = await gql({ query, authToken })
    return data
}
export const getPasscodeEntryById = async (id:string) => {
    const query = `query getEntryById {
        entry(id:"${id}") {
            id
        }
    }`
    console.log(query)
    interface DataT {
        errors?:ErrorT[],
        data?:{
            entry:{
                id:string
            }
        }
    }
    const data:DataT = await gql({ query })
    return data
}