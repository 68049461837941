import Cookies from 'js-cookie'

let entryId = document.body.getAttribute('data-entry-id');

if ( entryId ) {
    entryId = parseInt(entryId)
}

const cookieExpiryInterval = 1 // days

export const hudsonCookieName = 'hudson_221015'

export const setCookie = (cookieName, data) => Cookies.set(cookieName, JSON.stringify(data))

export const readCookie = (cookieName) => Cookies.get(cookieName) ? JSON.parse(Cookies.get(cookieName)) : null

export const addCookieData = (cookie, key, val) => {
    let newCookie = cookie ? cookie : {}
    // if entryId doesn't exist in array, add it
    if ( newCookie.hasOwnProperty(key) ) {
        if ( newCookie[key].indexOf(val) === -1 ) {
            newCookie[key].push(val);
        }
    } else {
        newCookie[key] = val
    }
    setCookie(hudsonCookieName,newCookie);
}
export const cookieHasExpired = (cookie) => {
    return new Date() > new Date(cookie.expiryDate)
}
export const removeCookie = (cookieName) => {
    console.log('removing cookie...')
    Cookies.remove(cookieName)
}
export const createNewCookie = (hudsonCookieName,checkoutId) => {
    console.log('creating new cookie...')
    // create session Id
    const sessionId = new Date().toISOString('en-UK').replaceAll(/\D/g,'') + Math.floor(Math.random() * 1000);

    // set cookie scaffold
    setCookie(hudsonCookieName, {
        "expiryDate":new Date(Date.now() + (86400 * 1000 * cookieExpiryInterval)).toISOString(), // days in milliseconds
        "sessionId":sessionId,
        "pagesVisited":[],
        "checkout":checkoutId
    })

    // add this page to cookie if it has an id
    entryId ?? addCookieData(readCookie(hudsonCookieName), 'pagesVisited', entryId);
}
export const defineClubCookie = (user) => {
    let club_cookie;
    if ( Cookies.get('hudson_club') !== undefined ) {
        club_cookie = readCookie('hudson_club');
    } else {
        // create session Id
        const sessionId = new Date().toISOString('en-UK').replaceAll(/\D/g,'') + Math.floor(Math.random() * 1000);

        // set cookie scaffold
        Cookies.set('hudson_club', JSON.stringify({
            sessionId:sessionId,
            user:user,
            favourites:{
                entries:[],
                tracks:[]
            }
        }))
    }
}
