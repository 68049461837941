'use strict';

import * as Utils from './utils.js';


export const carousel = () => {

    const paginateCarousel = ($carousel,direction) => {
        let $item = $carousel.querySelector('.ci');
        let itemWidth = $item.offsetWidth + parseInt(Utils.getStyle($item,'margin-right').split('px')[0]) - 1;
        let offset;

        if ( direction == 'prev' ) {
            offset = -(itemWidth * 2);
        } else {
            offset = itemWidth * 2;
        }
        $carousel.scrollBy({ left:offset, behavior:'smooth' });
    }

    const hideButtons = ($prev,$next) => {
        [$prev,$next].forEach($button => {
            $button.classList.add('hidden');
        })
    }

    const showHideCarouselButtons = ($carousel, $prev, $next) => {

        if ( window.innerWidth > Utils.breakpoints.xs ) {
            let scrollPos = 0;
            let $items = $carousel.querySelectorAll('.ci');
            let itemWidth = $items[0].offsetWidth + 10;
            let itemsWidth = $items.length * itemWidth;
            let carouselWidth = $carousel.clientWidth;

            // set top position of pag buttons by img height (css gives translateY(-50%) )
            let top = ( $items[0].querySelector('img').clientHeight / 2 ) + 3;
            [$prev,$next].forEach($button => {
                $button.style.top = top + 'px';
                // show the buttons (initally hidden)
                $button.classList.remove('hidden');
            });

            // show/hide buttons based on scroll position
            scrollPos = $carousel.scrollLeft;


            if ( scrollPos < 20 ) {
                $prev.classList.add('hidden');
            } else {
                $prev.classList.remove('hidden');
            }

            if ( (scrollPos + carouselWidth) > (itemsWidth - itemWidth) ) {
                $next.classList.add('hidden');
            } else {
                $next.classList.remove('hidden');
            }

            // hide buttons if carousel content is less than viewport width
            if ( itemsWidth < carouselWidth ) {
                hideButtons($prev,$next);
            }



        } else {
            hideButtons($prev,$next);
        }
    }
    document.querySelectorAll('.carousel').forEach($carousel => {
        let $prev = $carousel.parentNode.querySelector('.carousel-prev');
        let $next = $carousel.parentNode.querySelector('.carousel-next');




        $prev.addEventListener('click', () => {
            paginateCarousel($carousel,'prev');
        });

        $next.addEventListener('click', () => {
            paginateCarousel($carousel,'next');
        });

        // scroll event
        $carousel.addEventListener('scroll', () => {
            showHideCarouselButtons($carousel, $prev, $next);
        });

        // init
        showHideCarouselButtons($carousel, $prev, $next);

        // re-evaluate on window resize event
        window.addEventListener('resize', () => {
            showHideCarouselButtons($carousel, $prev, $next);
        });
    });

}
