<script setup lang="ts">
import { gql } from './utils'
import { computed, ComputedRef, onMounted, nextTick, Ref, ref, watch } from 'vue'
const entryId:string|null|undefined = document.body.getAttribute('data-entry-id')
const userId:string|null = document.body.getAttribute('data-user')

type FormatOption = "FLAC"|"MP3"|"WAV"|"AAC"|"custom"

type File = {
    url:string|null,
    filename:string|null,
    title:string
}

interface DownloadOptionBlock {
    file: File[],
    format: FormatOption,
    customFormat: string|null,
    price:string
}

type DataT = {
    data: {
        entry: {
            download: DownloadOptionBlock[]
        }
    }
}
type ErrorT = {
    errors:{
        message:string
    }[]
}

const downloadOptions:Ref<DownloadOptionBlock[]> = ref([])

const selectedFormat:Ref<FormatOption|null> = ref(null)
const formatOptions:Ref<FormatOption[]> = ref([])

const getFormatOptions = (downloadOptions:DownloadOptionBlock[]):FormatOption[] => {
    const availableOptions = downloadOptions.map((option:DownloadOptionBlock) => {
        if ( option.format !== 'custom' ) {
            return option.format
        } else
        if ( option.customFormat ) {
            return option.customFormat
        } else {
            return null
        }
    }).filter(val => val !== null) as FormatOption[]

    const options = [ ...new Set(availableOptions) ]
    console.log(options)
    return options
}

if ( entryId ) {
    const downloadMatrixFragment = `
        download {
            ...on download_downloadOption_BlockType {
                file {
                    url
                    filename
                    title
                }
                format
                customFormat
                price
            }
        }
    `
    const query = `
        query productDownloads($id:[QueryArgument]) {
            entry(id:$id) {
                ... on products_hudsonRelease_Entry {
                    ${downloadMatrixFragment}
                }
            }
        }
    `
    const variables = { id:entryId }
    
    
    gql(query, variables).then(data => {
        if ( 'data' in data && data.data?.entry?.download.length ) {
            downloadOptions.value = data.data.entry.download
            formatOptions.value = getFormatOptions(downloadOptions.value)
            selectedFormat.value = formatOptions.value[0]
        } else {
            console.error('Download fetch error')
        }
    })
}



const download:Ref<Omit<File, "title">> = ref({
    filename:null,
    url:null
})

const _handleDownload = async () => {
    if ( userId ) {
        if ( download.value.url ) {
            const a = document.createElement('a')
            a.href = download.value.url
            a.click()
        }
    } else {
        window.location.href = `${window.location.href}?m=1&auth=0&dl=${selectedFormat.value}`
    }
}
function setQueryStringParameter(name:string, value:string, append=false) {
    const url = new URL(window.document.URL);
    if (append) url.searchParams.append(name, value);
    else url.searchParams.set(name, value);
    window.history.replaceState(null, "", url.toString());
}
watch(selectedFormat, () => {
    const selectedBlock = downloadOptions.value.find(({ format, customFormat }) => {
        return format === selectedFormat.value || customFormat === selectedFormat.value
    })

    if ( selectedFormat.value && selectedBlock ) {
        const { filename, url } = selectedBlock.file[0]
        download.value = { filename, url }
        //setQueryStringParameter('dl', selectedFormat.value)
    }
}, { 
    immediate:true,
    deep:true
})

</script>

<template>
    <div class="text-black">
        <div class="hidden sm:block text-base font-hudson-bold uppercase tracking-wide">Download</div>
        <div class="block sm:flex gap-5 w-full justify-between items-center ">
            <div class="flex w-full justify-between text-xs leading-none items-center mt-2">
                <div class="flex gap-2 items-center">
                    <div class="">Format:</div>
                    <select class="rounded text-xs border text-gray-800 px-2 py-1" v-model="selectedFormat">
                        <option v-for="format, i in formatOptions">{{format}}</option>
                    </select>
                </div>
                <button @click="_handleDownload()" class="button bg-club-sage hover:bg-black text-white" title="Download">
                    <span>Download</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                </button>
            </div>
            
        </div>
    </div>
</template>